import React, {useEffect} from "react";
import "./DattoRmmDetails.css"; 
import photo1 from "../../Assets/itglue.jpg"                                                                                                                                                                                                                                                                                                                  
import photo2 from "../../Assets/itgluedevice.jpg"
import photo3 from "../../Assets/itgluefeatures.jpg"
import photo4 from "../../Assets/itgluedashboard.png"
import photo5 from "../../Assets/itgluenetwork.png"
import Aos from "aos";
import "aos/dist/aos.css";

const ItGlueDetails = () => {
    useEffect(() => {
        Aos.init({once:true, duration: 2500});
    }, []); 
    return ( 
        <section className="rmm__section">
            <div className="rmm__section__container">
                <div className="rmm__section__card">
                    <div data-aos="fade-right" className="rmm__section__info">
                        <h1 className="rmm__section__title-1">Organized Documentation and Management Of Business Data: ITGlue</h1>
                        <p  className="rmm__section__description">
                            ITGlue provides a multiplicity of tools and services for 
                            documenting and managing your devices, empowering you to 
                            streamline operations, enhance collaboration, and enhance 
                            business productivity.
                        </p>
                    </div>
                    <div data-aos="fade-left" className="rmm__section__image">
                        <img src={photo1} alt="img 1" className="image" style={{height:'100%', width:'100%', objectFit:'cover',borderRadius:"5px", boxShadow: " 10px 10px 10px rgba(128, 128, 128, 0.3)"}}/>
                    </div>
                </div>
                <div className="rmm__section__card">
                    <div data-aos="fade-right" className="rmm__section__image-2">
                            <img src={photo2} alt="img 2" className="image" style={{height:'100%', width:'100%', objectFit:"cover", borderRadius:"5px", boxShadow: "0 4px 8px 0 rgba(255, 255, 255, 0.404), 0 6px 20px 0 rgba(255, 255, 255, 0.411)"}}/>
                        </div>
                    <div data-aos="fade-left" className="rmm__section__info">
                        <h1 className="rmm__section__title-1">Amalgamated Documentation Management</h1>
                        <p  className="rmm__section__description">
                            ITGlue offers a centralized platform for documenting all your devices, 
                            configurations, and processes. Say goodbye to scattered documentation 
                            and hello to organized, easily accessible information at your fingertips.
                        </p>
                        <h1 className="rmm__section__title-1">Comprehensive Asset Management</h1>
                        <p  className="rmm__section__description">
                            With ITGlue, you can efficiently manage all your devices and assets in 
                            one place. From hardware specifications to software licenses and warranties,
                            we've got you covered, ensuring that nothing falls through the cracks.
                        </p>
                    </div>
                </div>
                <div className="rmm__section__card">
                    <div data-aos="fade-right" className="rmm__section__info">
                        <h1 className="rmm__section__title-1">Secure Documentation Storage</h1>
                        <p  className="rmm__section__description">
                            ITGlue prioritizes the security of your documentation with robust 
                            encryption protocols and access controls. Rest easy knowing that 
                            your sensitive information is stored securely, protected against 
                            unauthorized access and data breaches.
                        </p>
                        <h1 className="rmm__section__title-1">Efficient Collaboration and Knowledge Sharing</h1>
                        <p  className="rmm__section__description">
                            Collaborate seamlessly with your team members and share knowledge effortlessly with 
                            ITGlue's collaboration features. Whether you're working on a project together or sharing 
                            best practices, our platform fosters teamwork and knowledge exchange.
                        </p>
                    </div>
                    <div data-aos="fade-left" className="rmm__section__image" >
                            <img src={photo3} alt="img 3" className="image" style={{height:'100%', width:'100%', objectFit:"cover", borderRadius:"5px", boxShadow: " 10px 10px 10px rgba(128, 128, 128, 0.3)"}}/>
                        </div>
                </div>
                <div className="rmm__section__card">
                    <div data-aos="fade-right" className="rmm__section__image-2">
                            <img src={photo4} alt="img 4" className="image" style={{height:'100%', width:'100%', objectFit:"cover", borderRadius:"5px", boxShadow: "0 4px 8px 0 rgba(255, 255, 255, 0.404), 0 6px 20px 0 rgba(255, 255, 255, 0.411)"}}/>
                        </div>
                    <div className="rmm__section__info">
                        <h1 data-aos="fade-left" className="rmm__section__title-1">Integration with Third-Party Tools</h1>
                        <p data-aos="fade-left" className="rmm__section__description">
                            ITGlue integrates seamlessly with a wide range of third-party tools and solutions, 
                            enhancing your overall IT ecosystem. Whether it's RMM platforms, PSA tools, or 
                            documentation repositories, we ensure interoperability to maximize productivity 
                            and efficiency.
                        </p>
                        <h1 data-aos="fade-left" className="rmm__section__title-1">Real-Time Visibility and Insights</h1>
                        <p data-aos="fade-left" className="rmm__section__description">
                            Gain real-time visibility into your device environment with ITGlue's interactive 
                            dashboards and reports. Track device status, performance metrics, and historical 
                            data, empowering you to make informed decisions and optimize device management 
                            strategies.
                        </p>
                    </div>
                </div>
                <div className="rmm__section__card">
                    <div data-aos="fade-right" className="rmm__section__info">
                        <h1 className="rmm__section__title-1">Automated Documentation Updates</h1>
                        <p className="rmm__section__description">
                            Keep your documentation up-to-date with ITGlue's automated synchronization 
                            and update capabilities. Our platform syncs with your devices and systems, 
                            automatically capturing changes and updates in real-time, eliminating manual 
                            data entry and ensuring accuracy.
                        </p>
                        <h1 className="rmm__section__title-1"> Tailored Configuration Documentation</h1>
                        <p className="rmm__section__description">
                            Tailor configuration documentation to meet the specific needs of your organization 
                            with ITGlue's customizable templates. Capture detailed information about device 
                            configurations, network settings, and more, enabling you to standardize processes 
                            and improve efficiency.
                        </p>
                    </div>
                    <div data-aos="fade-left" className="rmm__section__image">
                            <img src={photo5} alt="img 5" className="image" style={{height:'100%', width:'100%', objectFit:"cover", borderRadius:"5px", boxShadow: " 10px 10px 10px rgba(128, 128, 128, 0.3)"}}/>
                        </div>
                </div>
            </div>
        </section>
     );
}
 
export default ItGlueDetails;