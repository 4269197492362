import React from 'react';
import './Hero.css'
import backgroundImage from '../../Assets/businessimage4.jpg';
import { Breadcrumbs, Link, Typography, styled } from '@mui/material';

const StyledBreadcrumbs = styled(Breadcrumbs)({
  '& .MuiBreadcrumbs-separator': {
    color: 'white',
    fontWeight: 'bold',
  },
});

const ProductsHero = () => {

    return (
        <header className='header' style={{backgroundImage: `linear-gradient(rgba(36, 26, 77, 0.8), rgba(36, 26, 77, 0.8)), url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition:"top"}}>
            <div className="header__container">
                <h1 className="header__title">products</h1>
                <StyledBreadcrumbs aria-label="breadcrumb">
                    <Link style={{ color: 'white', fontWeight: 'bold' }} href="/">
                        Home
                    </Link>
                    <Typography style={{ color: 'white', fontWeight: 'bold' }}>Products</Typography>
                </StyledBreadcrumbs>
            </div>
        </header>
    );
};

export default ProductsHero;
