import React, {useEffect} from 'react';
import img1 from '../../../Assets/network.jpg';
import './ManagedServicesProvider.css';
import Aos from 'aos';
import "aos/dist/aos.css";

const Networkcontent = () => {
    useEffect(() => {
        Aos.init({duration: 2500, once:true});
    }, []);

    return ( 
        <section className='details__sec'>
            <h2 data-aos="fade-down" className='details__title'>
            Networking Services
            </h2>
            <p data-aos="fade-left"  className='details__desc'>
            Our networking services encompass various crucial elements, including
            </p>
            <ul data-aos="fade-left" className='details__list'> 
                <li>
                Implementation of structured cabling infrastructure for seamless connectivity
                </li>
                <li>
                Secure equipment housing with mounting cabinets
                </li>
                <li>
                Installation of switches for efficient network traffic management
                </li>
            </ul>
            <div className='details__image'>
                <img src={img1} alt="img 1" style={{width:"100%"}}/>
            </div>

            <p data-aos="fade-right"  className='details__desc'>
            Additionally, our services involve:
            </p>
           
            <ul data-aos="fade-left" className='details__list'> 
                <li>
                Crafting tailored network solutions such as secure Site-to-Site VPNs, extensive Wide Area Networks
                (WANs), and localized Local Area Networks (LANs)
                </li>
                <li>
                Procurement services to obtain all necessary networking equipment, offering comprehensive
                assistance in building and sustaining a robust network infrastructure.
                </li>
            </ul>
           

        </section>
     );
}
 
export default Networkcontent;