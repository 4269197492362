import React, {useEffect} from 'react';
import img1 from '../../../Assets/server.png';
import './ManagedServicesProvider.css';
import Aos from 'aos';
import "aos/dist/aos.css";

const ServerView = () => {
    useEffect(() => {
        Aos.init({duration: 2500, once:true});
    }, []);

    return ( 
        <section className='details__sec'>
            <h2 data-aos="fade-down"  className='details__title'>
            Server infrastructure
            </h2>
            <p data-aos="fade-right" className='details__desc'>
            The delivery of server infrastructure services entails comprehensive installation and configuration of
            server hardware and storage solutions. This involves setting up server racks to organize and house
            equipment efficiently, optimizing space and airflow.  
            </p>
            <div className='details__image'>
                <img src={img1} alt="img 1" style={{width:"100%"}}/>
            </div>

            <p data-aos="fade-left" className='details__desc'>
            Additionally, services include:

            </p>
           
            <ul data-aos="fade-right" className='details__list'> 
                <li>
                Configuring Uninterruptible Power Supplies (UPS) to safeguard against power interruptions and ensure continuous
                operation. 
                </li>
                <li>
                Expert installation and setup of UPS systems enhance system reliability, protecting critical
                data and operations from potential disruptions
                </li>
            </ul>

        </section>
     );
}
 
export default ServerView;