import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HomeHeader from '../../Components/Header/HomeHeader';
import ServiceDetails from '../../Components/Hero/ServiceDetails';
import Navbar from '../../Components/ServicesDetails/NavBar';
import Cloudcontent from '../../Components/ServicesDetails/ServicesDisplay/CloudComputeSolutions';
import backgroundImage from '../../Assets/wallpaperservicces.jpg';
import './Details.css';
import ScrollToTopButton from '../../Components/ScollButton/ScrollButton';
import Footer from '../../Components/Footer/Footer';
import logo1 from '../../Assets/aws.png';
import logo2 from '../../Assets/azure.png';
import logo3 from '../../Assets/microsoft.webp';
import logo4 from '../../Assets/google.png';

const Cloud = () => {
    const sliderSettings = {
        speed: 500,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 1000,
        slidesToShow: 5,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 3,
                },
            },
        ],
    };

    return ( 
        <section style={{overflowX: 'hidden'}}>
            <HomeHeader/>
            <ServiceDetails/>
            <div style={{backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)), url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center center', backgroundAttachment:'fixed'}}>
                <div className='details__cont'>
                    <div>
                        <Navbar/>
                    </div>
                    <div className='details__content' >
                        <Cloudcontent/> 
                    </div>
                </div>
                <div className='details__logos' style={{padding: '1rem'}}>
                    <Slider {...sliderSettings}>
                        <div>
                            <img src={logo1} alt="AWS" style={{ width: '90%', height: '5rem' }} />
                        </div>
                        <div>
                            <img src={logo2} alt="Azure" style={{ width: '90%', height: '6rem' }} />
                        </div>
                        <div>
                            <img src={logo3} alt="Microsoft" style={{ width: '90%', height: '5rem'}} />
                        </div>
                        <div>
                            <img src={logo4} alt="Google" style={{ width: '90%', height: '6rem'}} />
                        </div>
                    </Slider>
                </div>
            </div>
            <ScrollToTopButton/>
            <Footer/>
        </section>
     );
}
 
export default Cloud;
