import React from 'react';
import HomeHeader from '../Components/Header/HomeHeader';
import Footer from '../Components/Footer/Footer';
import GetQuote from '../Components/GetQuote/GetQuote';
import OurServices from '../Components/OurServices/OurServices';
import ServicesHero from '../Components/Hero/ServicesHero';
import ScrollToTopButton from '../Components/ScollButton/ScrollButton';
import './Services.css';

const Services = () => {
    return (
        <div className='main__services' style={{overflowX: 'hidden'}}>
            <HomeHeader />
            <ServicesHero />
            <div>
                <OurServices/>
            </div>
            <div className='main__services__quote'>
                <GetQuote />
            </div>
            <ScrollToTopButton/>
            <Footer/>
        </div>
);

}

export default Services;