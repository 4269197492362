import React, {useEffect} from 'react';
import './OurServices.css';
import Aos from 'aos';
import "aos/dist/aos.css";
import image1 from '../../Assets/services1.png'
import image2 from '../../Assets/services2.jpg'
import image3 from '../../Assets/services3.png'
import image4 from '../../Assets/services4.png'
const OurServices = () => {
    useEffect(() => {
        Aos.init({duration: 2500});
    }, []);

    return (
        <section className='ourservices'>
            <h3 data-aos="fade-down" className='ourservices__subtitle'>
                How we can help you
            </h3>
            <h2 data-aos="fade-right" className='ourservices__title'>
                Empowering Businesses with Superior IT Solutions
            </h2>
            <div  className='ourservices__list'>
                <div className='ourservices__sublist'>
                    <a href='/service/managed-services-provider' data-aos="fade-right" data-aos-delay="600" className='services'>
                        <div className= 'services__img'>
                            <img src= {image1} alt="img 1" style={{height:'100%', width: '100%', borderRadius: '25px',}}/>
                        </div>
                        <h3 className='services__name'>
                            Managed Services Provider
                        </h3>
                        <p className='services__description'>
                            Strengthen your cybersecurity with our tailored solutions. 
                            From Endpoint Management to Managed SOC  and antivirus services, we protect your network against threats.
                        </p>
                    </a>
                    <a href='/service/server-administration' data-aos="fade-left" data-aos-delay="600" className='services'>
                        <div className= 'services__img'>
                            <img src= {image2} alt="img 2" style={{height:'100%', width: '100%', borderRadius: '25px',}}/>
                        </div>
                        <h3 className='services__name'>
                            Server Administration
                        </h3>
                        <p className='services__description'>
                            Equip your server room with our tailored solutions.
                            We provide top-notch hardware, security, and monitoring for smooth operation and system performance.
                        </p>
                    </a>
                </div>
                <div className='ourservices__sublist'>
                    <a href='/service/cloud-compute-solutions' data-aos="fade-right" data-aos-delay="600" className='services'>
                        <div className= 'services__img'>
                            <img src= {image3} alt="img 3" style={{height:'100%', width: '100%', borderRadius: '25px',}}/>
                        </div>
                        <h3 className='services__name'>
                            Cloud Compute Solutions 
                        </h3>
                        <p className='services__description'>
                            Leverage our cloud expertise with the 'As a Service' model. 
                            Transition seamlessly to operational expenditure, saving costs and scaling as needed. 
                            Unlock flexibility and efficiency to propel your business forward.
                        </p>
                    </a>
                    <a href='/service/network-infrastruture' data-aos="fade-left" /*data-aos-delay="600"*/ className='services'>
                        <div className= 'services__img'>
                            <img src= {image4} alt="img 4" style={{height:'100%', width: '100%', borderRadius: '25px',}}/>
                        </div>
                        <h3 className='services__name'>
                            Network Infrastructure
                        </h3>
                        <p className='services__description'>
                            With 20+ years of networking expertise, we design and secure networks with precision. 
                            From local to wide area networks, wireless setups, and VPN configurations, 
                            we deliver precision and excellence.
                        </p>
                    </a>
                </div>
            </div>
        </section>

    );
}

export default OurServices;