import React, {useEffect} from "react";
import "./DattoRmmDetails.css"; 
import photo1 from "../../Assets/rmmwebinterface2.png"                                                                                                                                                                                                                                                                                                                  
import photo2 from "../../Assets/rmmwinner2.png"
import photo3 from "../../Assets/dattosecurity.png"
import photo4 from "../../Assets/rmmanalysis2.png"
import photo5 from "../../Assets/rmmpatch.jpg"
import Aos from "aos";
import "aos/dist/aos.css";

const DattoRmmDetails = () => {
    useEffect(() => {
        Aos.init({once:true, duration: 2500});
    }, []); 
    return ( 
        <section className="rmm__section">
            <div className="rmm__section__container">
                <div className="rmm__section__card">
                    <div data-aos="fade-right" className="rmm__section__info">
                        <h1 className="rmm__section__title-1">RMM Software Supports Your Business</h1>
                        <p  className="rmm__section__description">
                            Datto Remote Monitoring and Management (RMM) 
                            is a secure cloud-based RMM platform. You can remotely 
                            secure, monitor, and manage endpoints with Datto RMM to 
                            reduce costs and improve network efficiency.
                        </p>
                        <p className="rmm__section__description">
                            DattoRMM empowers you to take control of your IT infrastructure, 
                            enhance security, boost efficiency, and drive business success with
                            confidence. It's more than just a remote management tool—it's your 
                            partner in achieving IT excellence.
                        </p>
                    </div>
                    <div data-aos="fade-left" className="rmm__section__image" style={{marginBottom:'10rem'}}>
                        <img src={photo1} alt="img 1" className="image" style={{height:'100%', width:'100%', borderRadius:"5px", boxShadow: " 10px 10px 10px rgba(128, 128, 128, 0.3)"}}/>
                    </div>
                </div>
                <div className="rmm__section__card">
                    <div data-aos="fade-right" className="rmm__section__image-2">
                            <img src={photo2} alt="img 2" className="image" style={{height:'90%', width:'90%', objectFit:"cover", borderRadius:"5px", boxShadow: "0 4px 8px 0 rgba(255, 255, 255, 0.404), 0 6px 20px 0 rgba(255, 255, 255, 0.411)"}}/>
                        </div>
                    <div data-aos="fade-left" className="rmm__section__info">
                        <h1 className="rmm__section__title-1">Seamless Remote Management</h1>
                        <p  className="rmm__section__description">
                            Datto Remote Monitoring and Management (RMM) 
                            is a secure cloud-based RMM platform. You can remotely 
                            secure, monitor, and manage endpoints with Datto RMM to 
                            reduce costs and improve network efficiency.
                        </p>
                        <h1 className="rmm__section__title-1">Real-Time Monitoring</h1>
                        <p  className="rmm__section__description">
                            DattoRMM empowers you to effortlessly manage all your IT assets 
                            from a single, intuitive dashboard. Say goodbye to tedious manual 
                            tasks and embrace streamlined remote management like never before.
                        </p>
                    </div>
                </div>
                <div className="rmm__section__card">
                    <div data-aos="fade-right" className="rmm__section__info">
                        <h1 className="rmm__section__title-1">Robust Security Measures</h1>
                        <p  className="rmm__section__description">
                            Protect your sensitive data and networks with DattoRMM's robust 
                            security measures. From endpoint protection to encryption protocols, 
                            rest easy knowing that your IT environment is fortified against cyber 
                            threats.
                        </p>
                        <h1 className="rmm__section__title-1">Complete Protection Solution</h1>
                        <p  className="rmm__section__description">
                            Security features include anti-virus and anti-malware management, 
                            enabling security policy enforcement, and assisting with regulatory 
                            compliance.
                        </p>
                    </div>
                    <div data-aos="fade-left" className="rmm__section__image" style={{marginBottom:'10rem'}}>
                            <img src={photo3} alt="img 3" className="image" style={{height:'100%', width:'100%', objectFit:"cover", borderRadius:"5px", boxShadow: " 10px 10px 10px rgba(128, 128, 128, 0.3)"}}/>
                        </div>
                </div>
                <div className="rmm__section__card">
                    <div data-aos="fade-right" className="rmm__section__image-2">
                            <img src={photo4} alt="img 4" className="image" style={{height:'70%', width:'100%', borderRadius:"5px", boxShadow: "0 4px 8px 0 rgba(255, 255, 255, 0.404), 0 6px 20px 0 rgba(255, 255, 255, 0.411)"}}/>
                        </div>
                    <div className="rmm__section__info">
                        <h1 data-aos="fade-left" className="rmm__section__title-1">Reporting and Analytics</h1>
                        <p data-aos="fade-left" className="rmm__section__description">
                            Gain valuable insights into your IT environment with DattoRMM's 
                            comprehensive reporting and analytics. Track key performance metrics, 
                            identify trends, and make data-driven decisions to drive business growth.
                        </p>
                        <h1 data-aos="fade-left" className="rmm__section__title-1">Insightful Information At Your Fingertips</h1>
                        <p data-aos="fade-left" className="rmm__section__description">
                            Comprehensive reporting and analytics tools offer insights 
                            into network and device performance, helping you make informed 
                            decisions.
                        </p>
                    </div>
                </div>
                <div className="rmm__section__card">
                    <div data-aos="fade-right" className="rmm__section__info">
                        <h1 className="rmm__section__title-1">Automated Patch Management</h1>
                        <p className="rmm__section__description">
                            Keep your systems secure and up-to-date with automated 
                            patch management. DattoRMM ensures that your software is 
                            always patched with the latest updates, safeguarding your 
                            infrastructure against vulnerabilities.
                        </p>
                        <h1 className="rmm__section__title-1">Keep Systems Up-To-Date</h1>
                        <p className="rmm__section__description">
                            The platform offers automated patch management for operating 
                            systems and third-party software, reducing vulnerabilities and 
                            enhancing security.
                        </p>
                    </div>
                    <div data-aos="fade-left" className="rmm__section__image" style={{marginBottom:'10rem'}}>
                            <img src={photo5} alt="img 5" className="image" style={{height:'100%', width:'100%', objectFit:"cover", borderRadius:"5px", boxShadow: " 10px 10px 10px rgba(128, 128, 128, 0.3)"}}/>
                        </div>
                </div>
            </div>
        </section>
     );
}
 
export default DattoRmmDetails;