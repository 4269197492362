import React from 'react';
import ContactHeader from '../Components/Header/ContactHeader';
// import HomeHeader from '../Components/Header/HomeHeader';
import Footer from '../Components/Footer/Footer';
import ContactForm from '../Components/ContactUs/ContactForm';
import './ContactUs.css';
import ContactBar from '../Components/ContactBar';
import ContactUsHero from '../Components/Hero/ContactUsHero';
import ScrollToTopButton from '../Components/ScollButton/ScrollButton';

const ContactUs = () => {
    return ( 
        <div className='contactus' style={{overflowX: 'hidden'}}>
            <ContactBar  />
            <ContactHeader/>
            <ContactUsHero/>
            <ContactForm  />
            <ScrollToTopButton/>
            <Footer/>

        </div>
);
}
 
export default ContactUs;