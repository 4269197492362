import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import emailjs from '@emailjs/browser'

const styles = {
  card: {
    borderRadius: 5,
    padding: '5%', 
  },
  messageField: {
    width: '98%',
    // height: '50%',
    padding: '1rem 0.5rem',
    margin: 'auto', 
  },
  text: {
    textAlign: 'left', 
    marginBottom: '5%',
  },
  buttonContainer: {
    textAlign: 'left',
  },
  button: {
    backgroundColor: '#205BF3',
    color: 'white', 
    marginTop: '3%',
  },
  successMessage: {
    backgroundColor: 'rgba(0, 128, 0, 0.3)',
    color: 'white',
    padding: '0.5rem',
    marginBottom: '0.5rem',
    borderRadius: '5px',
  },

  errorMessage:  {
    color: 'rgb(251, 86, 86)',
    marginBottom: '1rem',
    display: 'flex',
    marginLeft: '1rem',
  }
};

const HomeContact = () => {
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleFullnameChange = (event) => {
    setFullname(event.target.value);
  }

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setErrorMessage('');
  }

  const handleSubjectChange = (event) => {
    setSubject(event.target.value);
  }

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  }

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!validateEmail(email)) {
        setErrorMessage('Please enter a valid email address.');
        return;
    }

    emailjs.sendForm('service_ryr4jqv', 'template_7zg7jpy', event.target, 'gkJmILqd2ejfxVGd0')
      .then(() => {
        setSuccessMessage('Message sent successfully!');
        setTimeout(() => {
          setSuccessMessage('');
          setFullname('');
          setEmail('');
          setSubject('');
          setMessage('');
          setErrorMessage('');
        }, 3000);
      })
      .catch((error) => {
        console.error('Error sending message:', error);
        // Handle error
      });
  }

  return (
    <Card sx={styles.card}>
      <form onSubmit={handleSubmit}>
        <CardContent>
          <div style={{
              color: "#1227E1",
              fontStyle: "italic",
              fontSize: "1.2rem",
              textAlign: 'left',
            }}>
            <Typography variant="h7">
              Let's talk with us
            </Typography>
          </div>
          <Typography variant="h6" sx={styles.text}>
            Send Us a Message
          </Typography>
          {successMessage && (
            <div style={styles.successMessage}>
              {successMessage}
            </div>
          )}
          <div style={{height: '1rem'}}>  
          </div>
          <TextField
            label="Full Name"
            name="fullname"
            variant="outlined"
            required
            fullWidth
            // fullHeight
            margin="normal"
            sx={styles.messageField} 
            value={fullname}
            onChange={handleFullnameChange}
          />
          <TextField
            label="Email"
            name="email"
            variant="outlined"
            required
            fullWidth
            margin="normal"
            sx={styles.messageField} 
            value={email}
            onChange={handleEmailChange}
          />
            {errorMessage && (
            <div style={styles.errorMessage}>
                {errorMessage}
              </div>
            )}
          <TextField
            label="Subject"
            name="subject"
            variant="outlined"
            required
            multiline
            rows={2}
            fullWidth
            margin="normal"
            sx={styles.messageField}
            value={subject}
            onChange={handleSubjectChange} 
          />
          <TextField
            label="Message"
            name="message"
            variant="outlined"
            required
            multiline
            rows={4}
            fullWidth
            margin="normal"
            sx={styles.messageField} 
            value={message}
            onChange={handleMessageChange}
          />
          <div style={styles.buttonContainer}>
            <Button type="submit" variant="contained" endIcon={<SendIcon />} style={styles.button}>
              Send Message
            </Button>
          </div>
        </CardContent>
      </form>
    </Card>
  );
};

export default HomeContact;
