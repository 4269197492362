import React, {useEffect} from 'react';
import img1 from '../../../Assets/cloud.png';
import './ManagedServicesProvider.css';
import Aos from 'aos';
import "aos/dist/aos.css";

const Cloudcontent = () => {
    useEffect(() => {
        Aos.init({duration: 2500, once:true});
    }, []);

    return ( 
        <section className='details__sec'>
            <h2 data-aos="fade-down" className='details__title'>
            Cloud Compute Services
            </h2>
            <p data-aos="fade-left" className='details__desc'>
            The delivery of cloud services introduces the innovative "As a Service" model, offering clients the
            flexibility to access cutting-edge solutions without substantial initial investments. This model allows
            businesses to embrace an Operational Expenditure (Opex) approach, minimizing upfront costs.
            </p>
            <div className='details__image'>
                <img src={img1} alt="img 1" style={{height:"100%", width:"100%"}}/>
            </div>

            <p data-aos="fade-right" className='details__desc'>
            By leveraging cloud computing, clients can utilize a range of services tailored to their needs, such as
            Infrastructure as a Service (IaaS) and Software as a Service (SaaS). 

            </p>
            <p data-aos="fade-up" className='details__desc'>
            This method guarantees scalability, agility, and cost-effectiveness, providing high-quality cloud services to each client and
            improving operational efficiency and resource management
            </p>
        </section>
     );
}
 
export default Cloudcontent;