import React, { useState } from 'react';
import './ContactHeader.css';
import logo from '../../Assets/logo.png';  
import MenuIcon from '@mui/icons-material/Menu';

const HomeHeader = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <header className="contact__header">
      <nav className="contact__header__nav__main">
       <a href='/'> <img src={logo} alt="Logo" className="contact__header__logo" /> </a>  
        <div className='contactblack__bg'>
        </div>
        <div className="contact__header__nav">
          <a href="/" className="contact__header__nav__link">Home</a>
          <a href="/about" className="contact__header__nav__link">About</a>
          <a href="/product" className="contact__header__nav__link">Products</a>
          <a href="/service" className="contact__header__nav__link">Services</a>
          <a href="/contact" className="contact__header__nav__link">Contact</a>
        </div>
        <div className="Header__menuicon" onClick={toggleNav}>
          <MenuIcon style={{fontSize: '3rem'}} />
        </div>
      </nav>
      {isNavOpen && (
        <section className='contactnav__main'>
        <nav className="contactnav">
          <a href="/" style={{textDecoration: 'none', fontSize: '1.5rem'}} className="contactnav__link">Home</a>
          <a href="/about" style={{textDecoration: 'none', fontSize: '1.5rem'}} className="contactnav__link">About</a>
          <a href="/product" style={{textDecoration: 'none', fontSize: '1.5rem'}} className="contactnav__link">Products</a>
          <a href="/service" style={{textDecoration: 'none', fontSize: '1.5rem'}} className="contactnav__link">Services</a>
          <a href="/contact" style={{textDecoration: 'none', fontSize: '1.5rem'}} className="contactnav__link">Contact</a>
        </nav>
        </section>
       
      )}
    </header>
  );
};

export default HomeHeader;
