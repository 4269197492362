import React, {useEffect} from "react";
import "./DattoRmmDetails.css"; 
import photo1 from "../../Assets/bcdrcloud.jpg"                                                                                                                                                                                                                                                                                                                  
import photo2 from "../../Assets/bcdrrecovery.jpg"
import photo3 from "../../Assets/bcdrglobe.jpg"
import photo4 from "../../Assets/bcdrserver.jpg"
import Aos from "aos";
import "aos/dist/aos.css";

const BCDRDetails = () => {
    useEffect(() => {
        Aos.init({once:true, duration: 2500});
    }, []); 
    return ( 
        <section className="rmm__section">
            <div className="rmm__section__container">
                <div className="rmm__section__card">
                    <div data-aos="fade-right" className="rmm__section__info">
                        <h1 className="rmm__section__title-1">Business Continuity and Disaster Recovery (BCDR)</h1>
                        <p  className="rmm__section__description">
                            Datto BCDR provides comprehensive data protection and disaster recovery 
                            solutions that empower you to safeguard your devices, mitigate risks, 
                            and ensure business continuity in the face of any adversity.
                        </p>
                    </div>
                    <div data-aos="fade-left" className="rmm__section__image">
                        <img src={photo1} alt="img 1" className="image" style={{height:'100%', width:'100%', objectFit:'cover',borderRadius:"5px", boxShadow: " 10px 10px 10px rgba(128, 128, 128, 0.3)"}}/>
                    </div>
                </div>
                <div className="rmm__section__card">
                    <div data-aos="fade-right" className="rmm__section__image-2">
                            <img src={photo2} alt="img 2" className="image" style={{height:'100%', width:'100%', objectFit:'cover', borderRadius:"5px", boxShadow: "0 4px 8px 0 rgba(255, 255, 255, 0.404), 0 6px 20px 0 rgba(255, 255, 255, 0.411)"}}/>
                        </div>
                    <div data-aos="fade-left" className="rmm__section__info">
                        <h1 className="rmm__section__title-1">Bulletproof Data Protection</h1>
                        <p  className="rmm__section__description">
                            Datto BCDR offers bulletproof data protection for your devices, ensuring 
                            that your critical business data is safe and secure, no matter what.
                        </p>
                        <h1 className="rmm__section__title-1">Rapid Disaster Recovery</h1>
                        <p  className="rmm__section__description">
                            When disaster strikes, Datto BCDR enables rapid recovery of your devices and data, 
                            minimizing downtime and ensuring business continuity.
                        </p>
                    </div>
                </div>
                <div className="rmm__section__card">
                    <div data-aos="fade-right" className="rmm__section__info">
                        <h1 className="rmm__section__title-1">Continuous Data Protection</h1>
                        <p  className="rmm__section__description">
                            Say goodbye to outdated backups and hello to up-to-the-minute data protection, 
                            ensuring that you never lose more than a few moments of work in the event of a 
                            disaster.
                        </p>
                        <h1 className="rmm__section__title-1">Hybrid Cloud Backup</h1>
                        <p  className="rmm__section__description">
                            You get the benefits of local backup for fast recovery 
                            and offsite replication for added redundancy and disaster 
                            recovery preparedness.
                        </p>
                    </div>
                    <div data-aos="fade-left" className="rmm__section__image">
                            <img src={photo3} alt="img 3" className="image" style={{height:'100%', width:'100%', objectFit:"cover", borderRadius:"5px", boxShadow: " 10px 10px 10px rgba(128, 128, 128, 0.3)"}}/>
                        </div>
                </div>
                <div className="rmm__section__card">
                    <div data-aos="fade-right" className="rmm__section__image-2">
                            <img src={photo4} alt="img 4" className="image" style={{height:'70%', width:'100%', objectFit:"cover", borderRadius:"5px", boxShadow: "0 4px 8px 0 rgba(255, 255, 255, 0.404), 0 6px 20px 0 rgba(255, 255, 255, 0.411)"}}/>
                        </div>
                    <div className="rmm__section__info">
                        <h1 data-aos="fade-left" className="rmm__section__title-1">Flexible Recovery Options</h1>
                        <p data-aos="fade-left" className="rmm__section__description">
                            Datto BCDR offers flexible recovery options to meet the unique 
                            needs of your organization. Whether you need to recover individual 
                            files, entire systems, or even spin up virtual machines in the cloud, 
                            our platform provides the tools and capabilities you need to restore 
                            operations quickly and efficiently.
                        </p>
                        <h1 data-aos="fade-left" className="rmm__section__title-1">Proven ROI and Business Value</h1>
                        <p data-aos="fade-left" className="rmm__section__description">
                            By protecting your devices and data from unexpected disruptions, 
                            Datto BCDR helps safeguard your organization's reputation, customer 
                            trust, and bottom line, ensuring that you can weather any storm and 
                            emerge stronger than ever.
                        </p>
                    </div>
                </div>
            </div>
        </section>
     );
}
 
export default BCDRDetails;