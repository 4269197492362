import React from 'react';
import businessimage1 from "../Assets/businessimage1.jpg";
import businessimage2 from "../Assets/businessimage2.jpg"; 
import businessimage3 from "../Assets/businessimage3.jpg";
import HomeHeader from '../Components/Header/HomeHeader'; 
import Footer from '../Components/Footer/Footer';
import Slideshow from '../Components/SlideShow/SlideShow';
import HomeServices from '../Components/HomeServices/HomeServices';
import HomeDetails from '../Components/HomeDetails/HomeDetails';
import GetQuote from '../Components/GetQuote/GetQuote';
import ScrollToTopButton from '../Components/ScollButton/ScrollButton';

function Home() {

  const images = [
    businessimage1,
    businessimage2,
    businessimage3,
  ];

  return (
    <div className="App" style={{backgroundColor:"#000",  overflowX: "hidden"}}>
      <HomeHeader />

      <div>  
        <Slideshow images={images} />
      </div>
      <HomeDetails />
      <HomeServices />
      <GetQuote/>
      <ScrollToTopButton/>
      <Footer/>
    </div>
  
  );
}

export default Home;
