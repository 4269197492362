import React, {useEffect} from 'react';
import Aos from "aos";
import 'aos/dist/aos.css';
import './HomeServices.css';
import photo1 from '../../Assets/businessimage4.jpg'
import photo2 from '../../Assets/consultingimage.png' 
import photo3 from '../../Assets/businessimage5.jpg'
import photo4 from '../../Assets/network.png'
import photo5 from '../../Assets/businessimage6.jpg'
import photo6 from '../../Assets/clouddata.png'
import photo7 from '../../Assets/businessimage7.jpg'
import photo8 from '../../Assets/cyberimage.png'

const HomeServices = () => {
    useEffect(() => {
        Aos.init({duration: 2500});
    }, []);
    return ( 
        <section className="hero">
            <h1 data-aos="fade-up" className='service__section__title-1'>services</h1>
            <h2 data-aos="fade-up" className='service__section__title'>solutions for IT business</h2>
            <div className='section__main__container'>
                <div className="section__container">
                    <div className='section__sublist'>
                        <div className="section">
                            <div data-aos="fade-right" data-aos-duration="800" className='section__img'>
                                <img src={photo1} alt="img 1" style={{height:'100%', width:'100%', objectFit:'cover'}}/>
                            </div>
                            <div data-aos="fade-up" data-aos-duration="800" className="section__card" >
                                <div className="section__img-2">
                                    <img src={photo2} alt="img 2" style={{height:'100%', width:'100%', objectFit:'cover'}}/>
                                </div>
                                <h3 className="section__title-2">IT Consultancy</h3>
                                <p className="section__description-2">
                                    We help you identify which IT services are the most
                                    beneficial for your business.
                                    </p>
                            </div>
                        </div>
                        <div className="section">
                            <div data-aos="fade-right" data-aos-duration="800" data-aos-once="true" className="section__img">
                                <img src={photo3} alt="img 3" style={{height:'100%', width:'100%', objectFit:'cover'}}/>
                            </div>
                            <div data-aos="fade-up" data-aos-duration="800" data-aos-once="true" className="section__card">
                                <div className="section__img-2">
                                    <img src={photo4} alt="img 4" style={{height:'100%', width:'100%', objectFit:'cover'}}/>
                                </div>
                                <h3 className="section__title-2">Network Security</h3>
                                <p className="section__description-2">
                                    Comprehensive assessments of clients' network infrastructure to 
                                    identify vulnerabilities and potential security risks.
                                    </p>
                            </div>
                        </div>
                    </div>
                    <div className='section__sublist'>
                        <div className="section">
                            <div data-aos="fade-left" data-aos-duration="800" data-aos-once="true" className="section__img">
                                <img src={photo5} alt="img 5" style={{height:'100%', width:'100%', objectFit:'cover'}}/>
                            </div>
                            <div data-aos="fade-up" data-aos-duration="800" data-aos-once="true" className="section__card">
                                <div className="section__img-2">
                                    <img src={photo6} alt="img 6" style={{height:'100%', width:'100%', objectFit:'cover'}}/>
                                </div>
                                <h3 className="section__title-2">Cloud Solutions</h3>
                                <p className="section__description-2"> 
                                    Assist clients in migrating to and managing cloud platforms
                                    including setup, migration and ongoing management.
                                </p>
                            </div>
                        </div>
                        <div className="section">
                            <div data-aos="fade-left" data-aos-duration="800" data-aos-once="true" className="section__img">
                                <img src={photo7} alt="img 7" style={{height:'100%', width:'100%', objectFit:'cover'}}/>
                            </div>
                            <div data-aos="fade-up" data-aos-duration="800" data-aos-once="true" className="section__card">
                                <div className="section__img-2">
                                    <img src={photo8} alt="img 8" style={{height:'100%', width:'100%', objectFit:'cover'}}/>
                                </div>
                                <h3 className="section__title-2">Cyber Security</h3>
                                <p className="section__description-2">
                                    Security assessments, threat monitoring, and incident response to 
                                    protect clients from cyber threats and ensure regulatory compliance.
                                    </p>  
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
     );
}
 
export default HomeServices;