import React, {useEffect} from "react";
import "./DattoRmmDetails.css"; 
import photo1 from "../../Assets/autotaskdashboard.jpg"                                                                                                                                                                                                                                                                                                                  
import photo2 from "../../Assets/autotaskdevices.jpg"
import photo3 from "../../Assets/autotaskpsa.png"
import photo4 from "../../Assets/autotasktask.jpg"
import Aos from "aos";
import "aos/dist/aos.css";

const AutoTaskDetails = () => {
    useEffect(() => {
        Aos.init({once:true, duration: 2500});
    }, []); 
    return ( 
        <section className="rmm__section">
            <div className="rmm__section__container">
                <div className="rmm__section__card">
                    <div data-aos="fade-right" className="rmm__section__info">
                        <h1 className="rmm__section__title-1">Device Management and Endpoint Performance Optimization</h1>
                        <p  className="rmm__section__description">
                            AutoTask provides a comprehensive suite of services for managing your 
                            devices, empowering you to optimize performance, enhance security, and 
                            drive business success.
                        </p>
                    </div>
                    <div data-aos="fade-left" className="rmm__section__image">
                        <img src={photo1} alt="img 1" className="image" style={{height:'100%', width:'100%', objectFit:'cover',borderRadius:"5px", boxShadow: " 10px 10px 10px rgba(128, 128, 128, 0.3)"}}/>
                    </div>
                </div>
                <div className="rmm__section__card">
                    <div data-aos="fade-right" className="rmm__section__image-2">
                            <img src={photo2} alt="img 2" className="image" style={{height:'100%', width:'100%', objectFit:"cover", borderRadius:"5px", boxShadow: "0 4px 8px 0 rgba(255, 255, 255, 0.404), 0 6px 20px 0 rgba(255, 255, 255, 0.411)"}}/>
                        </div>
                    <div data-aos="fade-left" className="rmm__section__info">
                        <h1 className="rmm__section__title-1">Efficient Remote Monitoring and Management</h1>
                        <p  className="rmm__section__description">
                            With AutoTask, you can remotely monitor and manage all your devices with ease.
                            Keep tabs on device health, performance, and security from anywhere, at any time, 
                            empowering you to stay in control of your IT-based environment.
                        </p>
                        <h1 className="rmm__section__title-1">Personalized Alerts and Control</h1>
                        <p  className="rmm__section__description">
                            Tailor monitoring and alerts to meet the specific needs of your organization 
                            with AutoTask's customizable settings. Receive real-time notifications about 
                            critical events, performance issues, and security threats, enabling you to take 
                            prompt action when needed.
                        </p>
                    </div>
                </div>
                <div className="rmm__section__card">
                    <div data-aos="fade-right" className="rmm__section__info">
                        <h1 className="rmm__section__title-1">Scalable Solutions for Any Business Size</h1>
                        <p  className="rmm__section__description">
                            Whether you're a small business or a large enterprise, AutoTask scales to fit your needs. 
                            Our flexible solutions adapt to your organization's size and requirements, ensuring that 
                            you always have the right level of support and functionality.
                        </p>
                        <h1 className="rmm__section__title-1">Complete Protection Solution</h1>
                        <p  className="rmm__section__description">
                            Security features include anti-virus and anti-malware management, 
                            enabling security policy enforcement, and assisting with regulatory 
                            compliance.
                        </p>
                    </div>
                    <div data-aos="fade-left" className="rmm__section__image">
                            <img src={photo3} alt="img 3" className="image" style={{height:'100%', width:'100%', objectFit:"cover", borderRadius:"5px", boxShadow: " 10px 10px 10px rgba(128, 128, 128, 0.3)"}}/>
                        </div>
                </div>
                <div className="rmm__section__card">
                    <div data-aos="fade-right" className="rmm__section__image-2">
                            <img src={photo4} alt="img 4" className="image" style={{height:'100%', width:'100%', objectFit:"cover", borderRadius:"5px", boxShadow: "0 4px 8px 0 rgba(255, 255, 255, 0.404), 0 6px 20px 0 rgba(255, 255, 255, 0.411)"}}/>
                        </div>
                    <div className="rmm__section__info">
                        <h1 data-aos="fade-left" className="rmm__section__title-1">Cost-Effective Solution with Proven ROI</h1>
                        <p data-aos="fade-left" className="rmm__section__description">
                            Investing in AutoTask delivers a proven return on investment by streamlining device management, 
                            improving productivity, and enhancing security. With our cost-effective solution, you can achieve 
                            significant cost savings and efficiency gains, ensuring that every dollar spent translates into 
                            tangible value for your organization.
                        </p>
                        <h1 data-aos="fade-left" className="rmm__section__title-1">Detailed Analytics For Your Business</h1>
                        <p data-aos="fade-left" className="rmm__section__description">
                            Comprehensive reporting and analytics tools offer insights 
                            into network and device performance, helping you make informed 
                            decisions.
                        </p>
                    </div>
                </div>
            </div>
        </section>
     );
}
 
export default AutoTaskDetails;