// App.js
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Services from "./Pages/Services";
import Product from "./Pages/Product";
import ContactUs from "./Pages/ContactUs";
import Cloud from "./Pages/ServicesDetails/Cloud";
import Network from "./Pages/ServicesDetails/Network";
import Server from "./Pages/ServicesDetails/Server";
import ServicesProvider from "./Pages/ServicesDetails/ServicesProvider";
import DattoRmm from "./Pages/ProductsDetails/DattoRmm.js";
import RocketCyber from "./Pages/ProductsDetails/RocketCyber.js";
import DattoEdr from "./Pages/ProductsDetails/DattoEdr.js";
import AutoTask from "./Pages/ProductsDetails/AutoTask.js";
import ItGlue from "./Pages/ProductsDetails/ItGlue.js";
import Rft from "./Pages/ProductsDetails/Rft.js";
import BCDR from "./Pages/ProductsDetails/BCDR.js";

// 404 Not Found component
function NotFound() {
  return (
    <div>
      <h2>404 Not Found</h2>
      <p>The page you are looking for does not exist.</p>
    </div>
  );
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/service" element={<Services />} />
        <Route path="/product" element={<Product />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/service/Cloud-compute-Solutions" element={<Cloud />} />
        <Route path="service/network-infrastruture" element={<Network />} />
        <Route path="/service/server-administration" element={<Server />} />
        <Route path="/service/managed-services-provider" element={<ServicesProvider />} />
        <Route path="/product/datto-rmm" element={<DattoRmm />} />
        <Route path="/product/rocketcyber" element={<RocketCyber />} />
        <Route path="/product/datto-edr" element={<DattoEdr />} />
        <Route path="/product/autotask" element={<AutoTask />} />
        <Route path="/product/itglue" element={<ItGlue />} />
        <Route path="/product/rft" element={<Rft />} />
        <Route path="/product/bcdr" element={<BCDR />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
