import React, {useEffect} from "react";
import './MissionStatement.css';
import photo from "../Assets/aboutusphoto2.jpg";
import photo1 from "../Assets/mission.png";
import photo2 from "../Assets/vision.png";
import Aos from "aos";
import "aos/dist/aos.css";

const MissionStatement = () => {
    useEffect(() => {
        Aos.init({duration: 2500});
    }, []);

    return (  
        <section className='mission'>
            <div data-aos="fade-right" className='mission__img__container'>
                <img src={photo} alt="img 1" className='mission__img'/>
            </div>
            <div className='mission__vision'>
                <div data-aos="fade-up" className='missionstatment'>
                    <div data-aos="fade-up" className='missionstatment__img'>
                        <img src={photo1} alt="img 2" className='img'/>
                    </div>
                    <div data-aos="fade-up" className='missionstatment__content'>
                        <div className='vision__title'>
                            <h2 className='heading'>
                                Mission Statement
                            </h2>
                        </div>
                        <div className='missionstatement__desc'>
                            <p className='body'> 
                            Our mission is to be the driving force behind a secure and seamless business
                            landscape, empowering organizations to thrive and innovate with confidence.
                            </p>
                        </div>
                    </div>
                </div>
                <div data-aos="fade-up" className='vision'>
                    <div data-aos="fade-up" className='missionstatment__img'>
                        <img src={photo2} alt="img 3" className='img'/>
                    </div>
                    <div data-aos="fade-up" className='missionstatment__content'>
                        <div className='vision__title'>
                            <h2 className='heading'>
                                Vision
                            </h2>
                        </div>
                        <div className='missionstatement__desc'>
                            <p className='body'>
                            At Neodein IT Solutions, our vision is to empower businesses of all sizes – small, medium, and
                            large with the essential tools necessary to safeguard their assets and ensure uninterrupted
                            workflow efficiency. We aspire to be the driving force behind a secure and seamlessly
                            operational business landscape, where organizations can thrive and innovate with
                            confidence.
                            </p>
                        </div>
                    </div>
                </div>

            </div>

        </section>
    );
}
 
export default MissionStatement;