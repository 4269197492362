import React, {useEffect} from "react";
import "./DattoRmmDetails.css"; 
import photo1 from "../../Assets/rftthreats.png"                                                                                                                                                                                                                                                                                                                  
import photo2 from "../../Assets/rftgraph.jpg"
import photo3 from "../../Assets/rftvulscan.jpg"
import photo4 from "../../Assets/autotasktask.jpg"
import photo5 from "../../Assets/dattoedrlightbulb.jpg"
import Aos from "aos";
import "aos/dist/aos.css";

const RftDetails = () => {
    useEffect(() => {
        Aos.init({once:true, duration: 2500});
    }, []); 
    return ( 
        <section className="rmm__section">
            <div className="rmm__section__container">
                <div className="rmm__section__card">
                    <div data-aos="fade-right" className="rmm__section__info">
                        <h1 className="rmm__section__title-1">Unlocking IT Excellence: The Power of Rapid Fire Tools for Your Organization</h1>
                        <p  className="rmm__section__description">
                            Rapid Fire Tools provides a comprehensive suite of services for assessing, 
                            monitoring, and optimizing your devices and network.
                        </p>
                        <p className="rmm__section__description">
                            With our actionable 
                            insights, real-time alerts, and expert support, we're your trusted partner in
                            maximizing business productivity and efficiency to meet the requirements setforth. 
                        </p>
                    </div>
                    <div data-aos="fade-left" className="rmm__section__image">
                        <img src={photo1} alt="img 1" className="image" style={{height:'100%', width:'100%', objectFit:'cover',borderRadius:"5px", boxShadow: " 10px 10px 10px rgba(128, 128, 128, 0.3)"}}/>
                    </div>
                </div>
                <div className="rmm__section__card">
                    <div data-aos="fade-right" className="rmm__section__image-2">
                            <img src={photo2} alt="img 2" className="image" style={{height:'100%', width:'100%', objectFit:"cover", borderRadius:"5px", boxShadow: "0 4px 8px 0 rgba(255, 255, 255, 0.404), 0 6px 20px 0 rgba(255, 255, 255, 0.411)"}}/>
                        </div>
                    <div data-aos="fade-left" className="rmm__section__info">
                        <h1 className="rmm__section__title-1">Detailed Network Assessments</h1>
                        <p  className="rmm__section__description">
                            Rapid Fire Tools offers comprehensive network assessments that provide deep 
                            insights into the health, security, and performance of your devices and network 
                            infrastructure.
                        </p>
                        <p className="rmm__section__description">
                            With our assessments, you can uncover hidden issues, identify 
                            vulnerabilities, and make informed decisions to optimize your business environment.
                        </p>
                    </div>
                </div>
                <div className="rmm__section__card">
                    <div data-aos="fade-right" className="rmm__section__info">
                        <h1 className="rmm__section__title-1">Automated Network Discovery</h1>
                        <p  className="rmm__section__description">
                            Our platform automates the process of discovering devices on 
                            your network, ensuring that no device goes unnoticed. Whether 
                            it's workstations, servers, printers, or IoT devices, Rapid 
                            Fire Tools scans your network to provide a complete inventory 
                            of all connected devices.
                        </p>
                        <h1 className="rmm__section__title-1">Vulnerability Scanning and Assessment</h1>
                        <p  className="rmm__section__description">
                        Rapid Fire Tools conducts vulnerability scanning to identify 
                        potential security risks and weaknesses in your devices and 
                        network.
                        </p>
                        <p className="rmm__section__description">
                            The assessments provide detailed reports on vulnerabilities 
                            such as outdated software, misconfigured settings, and missing 
                            security patches, enabling you to prioritize remediation efforts 
                            effectively.
                        </p>
                    </div>
                    <div data-aos="fade-left" className="rmm__section__image">
                            <img src={photo3} alt="img 3" className="image" style={{height:'100%', width:'100%', objectFit:"cover", borderRadius:"5px", boxShadow: " 10px 10px 10px rgba(128, 128, 128, 0.3)"}}/>
                        </div>
                </div>
                <div className="rmm__section__card">
                    <div data-aos="fade-right" className="rmm__section__image-2">
                            <img src={photo4} alt="img 4" className="image" style={{height:'100%', width:'100%', objectFit:"cover", borderRadius:"5px", boxShadow: "0 4px 8px 0 rgba(255, 255, 255, 0.404), 0 6px 20px 0 rgba(255, 255, 255, 0.411)"}}/>
                        </div>
                    <div data-aos="fade-left" className="rmm__section__info">
                        <h1 className="rmm__section__title-1">Security and Compliance Audits</h1>
                        <p  className="rmm__section__description">
                            Ensure compliance with industry regulations and standards 
                            with Rapid Fire Tools' security and compliance audits.
                        </p>
                        <p className="rmm__section__description">
                            Our platform assesses your devices and network against 
                            regulatory requirements such as HIPAA, GDPR, and PCI DSS, 
                            helping you maintain compliance and avoid costly penalties.
                        </p>
                        <h1 data-aos="fade-left" className="rmm__section__title-1">Actionable Reporting and Recommendations</h1>
                        <p data-aos="fade-left" className="rmm__section__description">
                            Receive actionable reports and recommendations
                            that empower you to address issues and improve
                            the security and performance of your devices
                            and network.
                        </p>
                    </div>
                </div>
                <div className="rmm__section__card">
                    <div data-aos="fade-right" className="rmm__section__info">
                        <h1 className="rmm__section__title-1">Real-Time Monitoring and Alerts</h1>
                        <p className="rmm__section__description">
                            Rapid Fire Tools offers real-time monitoring
                            and alerts to keep you informed about the status 
                            of your devices and network.
                        </p>
                        <p className="rmm__section__description">
                            Receive instant notifications about critical events, 
                            performance issues, and security threats, allowing you 
                            to take prompt action to mitigate risks and prevent downtime.
                        </p>
                        <h1 className="rmm__section__title-1">Customizable Dashboards and Reporting</h1>
                        <p className="rmm__section__description">
                            Tailor dashboards and reports to meet the specific needs of your 
                            organization with Rapid Fire Tools' customizable options.
                        </p>
                    </div>
                    <div data-aos="fade-left" className="rmm__section__image">
                            <img src={photo5} alt="img 5" className="image" style={{height:'100%', width:'100%',  objectFit:"cover", borderRadius:"5px", boxShadow: " 10px 10px 10px rgba(128, 128, 128, 0.3)"}}/>
                        </div>
                </div>
            </div>
        </section>
     );
}
 
export default RftDetails;