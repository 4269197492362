import React from 'react';
import HomeHeader from '../Components/Header/HomeHeader';
import Products from '../Components/Products/Products';
import ProductsHero from '../Components/Hero/ProductsHero';
import Footer from '../Components/Footer/Footer';
import ScrollToTopButton from '../Components/ScollButton/ScrollButton';
import './Product.css';


const Product = () => {
    return ( 
        <div className='product' style={{overflowX: 'hidden'}}>
            <HomeHeader/>
            <ProductsHero/>
            <div>
                <Products />
            </div>
            <ScrollToTopButton/>
            <Footer />
        </div>
     );
}
 
export default Product;