import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './SlideShow.css';

const Slideshow =({ images }) => {
    const [currentImage, setCurrentImage] = useState(0);
  
    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentImage((prevImage) => (prevImage + 1) % images.length);
      }, 5000);
  
      return () => clearInterval(interval);
    }, [images]);

    return (
      <section class="slide container">
        <div class = "slide__container"
          style={{ 
            backgroundImage: `linear-gradient(rgba(0,0,0,0.75), rgba(0,0,0,0.75)), url(${images[currentImage]})`,
            backgroundSize: 'cover', 
            backgroundPosition: 'center center', 
            backgroundAttachment:'fixed',
            opacity: 1,
            transition: 'background-image 0.5s ease-out',
          }}
        >
          <section class="section__body">
            <h1 class="section__title">Neodein Inc.</h1> 
            <p class="section__description"> 
              Empowering businesses of all sizes with the essential 
              tools necessary to safeguard their assets and ensure uninterrupted
              workflow efficiency.
            </p>
            <div className='buttons'>
              <Link to="/service">
                <button type='button' className="btn section__btn-1">Services we provide</button>
              </Link>
              <Link to="/about">
                <button type='button' className="btn section__btn-2">Learn More</button>
              </Link>
            </div>
          </section>
        </div>
      </section>

    );
  }

  export default Slideshow;