import React from 'react';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import './ContactBar.css';

const ContactBar = () => {
    return (  
        <div className='contactbar'>
            <div className='contactbar__container'>
                <div className='contactbar__email__container'>
                    <div className='contactbar__emailicon'>
                    <EmailOutlinedIcon style={{ fontSize:'2.5rem', color: 'white'}}/> 
                    </div>
                    <div className='contactbar__email'>
                    info@neodein.com
                    </div>
                </div>
                <div className='contactbar__phone_container'>
                    <div className='contactbar__phoneicon'>
                    <PhoneIcon style={{ fontSize:'2.5rem', color: 'white'}}/>
                    </div>
                    <div className='contactbar__phone'>
                    645-6046 / 635-3620
                    </div>
                </div>
                
            </div>
        </div>
     
    );
}
 
export default ContactBar;