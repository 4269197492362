import React, {useEffect} from 'react';
import img1 from '../../../Assets/servicedetails1.jpg';
import './ManagedServicesProvider.css';
import Aos from 'aos';
import "aos/dist/aos.css";

const Serviceprovider = () => {
    useEffect(() => {
        Aos.init({duration: 2500, once:true});
    }, []);

    return ( 
        <section className='details__sec'>
            <div className='details__container'>
                <h2 data-aos="fade-down" className='details__title'>
                    Manage Services Provider
                </h2>
                <p data-aos="fade-left" className='details__desc'>
                    Managed Service Providers (MSPs) provide comprehensive solutions to businesses, ensuring strong IT
                    infrastructure and data security. With a focus on Endpoint Protection, MSPs protect devices and
                    networks from cyber threats using advanced security measures and real-time monitoring. 
                </p>
                <div className='details__image'>
                    <img src={img1} alt="img 1" style={{width:"100%"}}/>
                </div>
            
                <ul data-aos="fade-right" className='details__list'> 
                    <li>
                        Remote Management enables efficient IT operations, allowing MSPs to resolve issues and maintain system
                        health from a distance.
                    </li>
                    <li>
                        Antivirus as a Service ensures continuous defense against malware and
                        viruses, strengthening security measures. 
                    </li>
                    <li>
                        Backup as a Service includes On-Site and Cloud Backups,
                        offering redundancy and customized data recovery solutions.
                    </li>
                </ul>
                <p data-aos="fade-up" className='details__desc'>
                    MSP services offer a holistic approach to IT management, improving security, efficiency, and reliability for businesses of all sizes.
                </p>
            </div>
       
                

        </section>
     );
}
 
export default Serviceprovider;