import React from 'react';
import './Hero.css'
import backgroundImage from '../../Assets/businessimage5.jpg';
import { Breadcrumbs, Link, Typography, styled } from '@mui/material';

const StyledBreadcrumbs = styled(Breadcrumbs)({
  '& .MuiBreadcrumbs-separator': {
    color: 'white',
    fontWeight: 'bold',
  },
});

const ServicesDetailHero = () => {

  return (
  
    <header className='header' style={{backgroundImage: `linear-gradient(rgba(36, 26, 77, 0.8), rgba(36, 26, 77, 0.8)), url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition:"top"}}>
            <div className="header__container">
                <h1 className="header__title">Services Details</h1>
                <StyledBreadcrumbs aria-label="breadcrumb">
                    <Link style={{ color: 'white', fontWeight: 'bold' }} href="/">
                        Home
                    </Link>
                    <Link style={{ color: 'white', fontWeight: 'bold' }} href="/service">
                        Services
                    </Link>
                    <Typography style={{ color: 'white', fontWeight: 'bold' }}>Service Details</Typography>
                </StyledBreadcrumbs>
            </div>
        </header>
  
  );
};

export default ServicesDetailHero;
