import React, {useEffect} from 'react';
import backgroundImage from '../../Assets/qoute.png'; 
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import './GetQuote.css';
import Aos from "aos";
import { Link } from 'react-router-dom';

function GetQuote() {
  useEffect(() => {
    Aos.init({duration: 2500, once:true});
  }, []);

  return (
    <div data-aos="fade-up" className='getquote' style={{backgroundImage: `linear-gradient(rgba(18, 76, 226, 0.8), rgba(18, 76, 226, 0.8)), url(${backgroundImage})`, backgroundSize: 'cover'}}>
      <div className='getquote__container'>
        <h1 data-aos="fade-right" className= 'getquote__title'>Ready To Get Consultations For Any Kind Of IT Solutions? Get a Quote Now!</h1>
        <div className='getquote__button__container'> 
        <Link  style={{textDecoration:'none'}} to="/contact">
          <button type= 'button' className='getquote__button'>  
              <span className='getquote__button__title'>Get Quote</span>
              <ArrowForwardIcon className='getquote__arrow'/>
            </button> 
        </Link>
       
        </div>
      </div>
    </div>
  );
}

export default GetQuote;
