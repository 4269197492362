import React from "react";
import HomeHeader from "../../Components/Header/HomeHeader";
import Footer from "../../Components/Footer/Footer";
import ScrollToTopButton from "../../Components/ScollButton/ScrollButton";
import DattoRmmDetails from "../../Components/ProductsDetails/DattoRmmDetails";
import ProductsDetailsHero from "../../Components/Hero/ProductsDetailsHero";
import GetQuote from "../../Components/GetQuote/GetQuote";
import ProductBar from "../../Components/ProductsDetails/ProductsBar";


const DattoRmm = () => {
    return ( 
        <div className="dattoRmm" style={{backgroundColor:"#000",  overflowX: "hidden"}}>
            <HomeHeader />
            <ProductsDetailsHero />
            <ProductBar/>
            <div style={{marginTop: "5.5rem"}}>
                <DattoRmmDetails />
            </div>
            <ScrollToTopButton />
            <GetQuote />
            <ProductBar/>
            <Footer />
        </div>
     );
}
 
export default DattoRmm;