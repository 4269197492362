import React from 'react';
import logo from '../../Assets/logo.png'; 
import './Footer.css'
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import backgroundImage from '../../Assets/footer.png';

const Footer = () => {
  return (
  
   <footer className='footer' style={{backgroundImage: `linear-gradient(rgba(36, 26, 77, 0.8), rgba(36, 26, 77, 0.8)), url(${backgroundImage})`, backgroundSize: 'cover'}}>
    {/* <div className='footer__logo__title'>
      <img src={logo} alt="Logo" className="footer__logo" /> 
      <h2 className='footer__title'> Neodein IT Solutions</h2>
    </div> */}
    <div className='footer__des__nav'>
      <div className='footer__des__icons'>
          <div className='footer__logo__title'>
         <a href='/'> <img src={logo} alt="Logo" className="footer__logo" /> </a> 
            </div>
          <p className='footer__description'>
          Empowering businesses of all sizes with the essential tools necessary to safeguard their assets and ensure uninterrupted workflow efficiency.
              </p>
            <div className='footer__icons__list'>
              <a href='https://www.facebook.com'> <FacebookIcon className='footer__icon' style={{ fontSize:'2rem', color: 'white'}}/> </a>
              <a href='https://www.twitter.com'>  <XIcon className='footer__icon' style={{ fontSize:'2rem', color: 'white'}}/> </a>
              <a href='https://www.instagram.com'> <InstagramIcon className='footer__icon' style={{ fontSize:'2rem', color: 'white'}}/> </a>
              <a href='https://www.linkedin.com'>  <LinkedInIcon className='footer__icon' style={{ fontSize:'2rem', color: 'white'}}/> </a>
            </div>
      </div>
        <nav className='footer__nav'>
        <a href="/" className='footer__nav__item'>
            <KeyboardDoubleArrowRightIcon className='footer__arrow'/>
            Home
        </a>
        <a href="/about" className='footer__nav__item'>
            <KeyboardDoubleArrowRightIcon className='footer__arrow'/>
            About Us
        </a>
        <a href="/product" className='footer__nav__item'>
            <KeyboardDoubleArrowRightIcon className='footer__arrow'/>
            Products
        </a>
        <a href="/service" className='footer__nav__item'>
            <KeyboardDoubleArrowRightIcon className='footer__arrow'/>
            Services
        </a>
        <a href="/contact" className='footer__nav__item'>
            <KeyboardDoubleArrowRightIcon className='footer__arrow'/>
            Contact Us
        </a>
        </nav>
    </div>
    <div className='footer__line' />
    <p className='footer__copywrite'> © 2024 Neodein All Rights Reserved </p>
   </footer>
  
  );
};

export default Footer;
