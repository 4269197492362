import React,{useEffect} from "react";
import Aos from "aos";
import HomeContact from "../ContactUs/HomeContact";
import './HomeDetails.css';
import photo1 from '../../Assets/footer.png';

const HomeDetails = () => {
    useEffect(() => {
        Aos.init({duration: 2500, once:true});
    }, []);
    return(
        <div className="homedetails__section">
            <div className="homedetails__container">
                <div className="homedetails__body">
                    <h1 className="homedetails__title" data-aos="fade-down">about company</h1>
                    <p className="homedetails__description" data-aos="fade-right">
                        Neodein IT Solutions offers comprehensive IT services for businesses, 
                        including infrastructure management, hardware/software procurement, 
                        cloud solutions, and digital security. 
                    </p>
                    <p className="homedetails__description" data-aos="fade-right">
                        Our collaborative approach integrates seamlessly with your existing 
                        IT departments, enhancing protection and workflow efficiency. Trust us to 
                        handle your IT needs, freeing you to focus on business growth.
                    </p>
                    <div className="homedetails__img" data-aos="fade-up">
                        <img src={photo1} alt="businessimage" style={{height:"100%", width:"100%", objectFit:"cover"}}/>
                    </div>
                </div>
                <div className="homedetails__form__container" data-aos="fade-left" data-aos-duration="700">
                    <div className="homedetails__form">
                        <HomeContact/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeDetails;