import React, { useState } from "react";
import './ContactForm.css';
import TextField from '@mui/material/TextField';
import emailjs from "@emailjs/browser";

const ContactForm = () => {
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [email, setEmail] = useState("");
    const [phonenumber, setPhoneNumber] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const handleFirstnameChange = (event) => {
        setFirstname(event.target.value);
    }

    const handleLastnameChange = (event) => {
        setLastname(event.target.value);
    }

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
        setErrorMessage('');
    }

    const handlePhoneNumberChange = (event) => {
        setPhoneNumber(event.target.value);
    }

    const handleSubjectChange = (event) => {
        setSubject(event.target.value)
    }

    const handleMessageChange = (event) => {
        setMessage(event.target.value)
    }

    const validateEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
  }
    

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!validateEmail(email)) {
          setErrorMessage('Please enter a valid email address.');
          return;
      }
        setSuccessMessage('Message sent successfully!');
    
        emailjs.sendForm('service_ryr4jqv', 'template_humqoxg', event.target, 'gkJmILqd2ejfxVGd0')
          .then(() => {
            setSuccessMessage('Message sent successfully!');
            setTimeout(() => {
              setSuccessMessage('');
              setFirstname('');
              setLastname('');
              setEmail('');
              setPhoneNumber('');
              setSubject('');
              setMessage('');
              setErrorMessage('');
            }, 3000);
          })
          .catch((error) => {
            console.error('Error sending message:', error);
          });
    
        // const formData = {
        //     firstname,
        //     lastname,
        //     email,
        //     phonenumber,
        //     subject,
        //     message
        // };
    
        // axios.post("http://localhost:4000/accept", formData)
        //     .then(response => {
        //         console.log(response.data);
        //         console.log("successfully");
        //         console.log(formData);
        //     })
        //     .catch(error => {
        //         console.error('Error sending message:', error);
        //         console.log(formData);
        //     });
    
    }


    return (  
        <form className='contact' onSubmit={handleSubmit}>
          <div className="contact__container">
            <p className='contactus__para'>
              If you didn't find a specific service listed, don't worry!
            </p>
            <p className='contactus__para2'>
              We have a wide range of solutions tailored to meet
              your needs. Whether you're seeking a quote or
              exploring options to enhance your IT services, we're
              here to help. 
            </p>
            <p className='contactus__para2'>
              Get in touch using the contact portal
              below, and let's discuss how we can elevate your IT
              experience together.
            </p>
            <h2 className='contact__title'> 
              Send Us a Message
            </h2>
            {successMessage && (
              <div className="success__message">
                {successMessage}
              </div>
            )}
            <div className='contact__detailsform'>
              <div className='contact__subfield'>
                <TextField
                  required  
                  className='contact__textfield'
                  id="filled-multiline-flexible"
                  name="firstname"
                  label="First Name"
                  variant="filled"
                  margin="normal"
                  value={firstname}
                  onChange={handleFirstnameChange}
                />
                <TextField 
                  required
                  className='contact__textfield'
                  label="Last Name"
                  name="lastname"
                  id="filled-multiline-flexible"
                  variant="filled"
                  margin="normal"
                  value={lastname}
                  onChange={handleLastnameChange}
                />
              </div>
              <div className='contact__subfield'>
                <TextField 
                  required
                  className='contact__textfield'
                  name="email"
                  label="Email Address"
                  id="filled-multiline-flexible"
                  variant="filled"
                  margin="normal"
                  value={email}
                  onChange={handleEmailChange}
                />
                <TextField 
                  required
                  className='contact__textfield'
                  label="Contact Number"
                  name="contactnumber"
                  id="filled-multiline-flexible"
                  variant="filled"
                  margin="normal"
                  value={phonenumber}
                  onChange={handlePhoneNumberChange}
                />
              </div>
              {errorMessage && (
              <div className="error__message">
                {errorMessage}
              </div>
            )}
              <div className="contact__subfield2">
                <TextField 
                  required
                  className='contact__textfield2'
                  label="Subject"
                  name="subject"
                  id="filled-multiline-flexible"
                  variant="filled"
                  margin="normal"
                  value={subject}
                  onChange={handleSubjectChange}
                />
              </div>
            </div>
            <div className='contact__message'>
              <TextField
                required
                className='contact__messagefield'
                label="Message"
                name="message"
                id="filled-multiline-flexible"
                variant="filled"
                fullWidth
                multiline
                rows={6}
                margin="normal"
                value={message}
                onChange={handleMessageChange}
              />
            </div>
            <div className='contact__button__container'>
              <button type="submit" className='contact__button' variant="contained">
                Send Message
              </button>
            </div>
           
          </div>
        </form>
      );
      
}

export default ContactForm;
