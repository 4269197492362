import React from 'react';
import './ProductBar.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ProductBar = () => {
  const sliderSettings = {
    dots: true,
    speed: 500,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 1500,
    slidesToShow: 7,
    responsive: [
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
            },
        },
        {
            breakpoint: 825,
            settings: {
                slidesToShow: 2,
            },
        },
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
            },
        },
        {
            breakpoint: 1600,
            settings: {
                slidesToShow: 5,
            },
        },
    ],
};


return ( 
  <div className='product__main' style={{backgroundColor: 'rgba(255, 255, 255)',boxShadow: '0 4px 8px 0 rgba(255, 255, 255, 0.404), 0 6px 20px 0 rgba(255, 255, 255, 0.411)', padding: '1rem', }}>
          <Slider {...sliderSettings} className='main__slider'>
              <div className='productnav__section'>
                 <a href="/product/datto-rmm" className="product__nav__link">Datto RMM</a>
              </div>
              <div className='productnav__section'>
                 <a href="/product/rocketcyber" className="product__nav__link">RocketCyber</a>
              </div>
              <div className='productnav__section'>
                <a href="/product/datto-edr" className="product__nav__link">Datto EDR</a>
              </div>
              <div className='productnav__section'>
                 <a href="/product/autotask" className="product__nav__link">AutoTask</a>
              </div>
              <div className='productnav__section'>
                 <a href="/product/itglue" className="product__nav__link">IT Glue</a>
              </div>
              <div className='productnav__section'>
                 <a href="/product/rft" className="product__nav__link">RapidFire Tools</a>
              </div>
              <div className='productnav__section'>
                 <a href="/product/bcdr" className="product__nav__link">BCDR</a>
              </div>
          </Slider>
      </div>

);
};

export default ProductBar;

