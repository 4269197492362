import React, {useEffect} from "react";
import Groups2Icon from '@mui/icons-material/Groups2';
import photo from '../../Assets/aboutus.jpg';
import './AboutUs.css'
import Aos from "aos";
import "aos/dist/aos.css";

const Aboutus= () => {
    useEffect(() => {
        Aos.init({once:true, duration: 2500});
    }, []);

    return(
    <section className='aboutus'>
        <div className="aboutus__container">
            <div  className='aboutus__info'>
                <h2 data-aos="fade-down" className='aboutus__title'> About Company</h2>
                <p data-aos="fade-up" className='aboutus__description'>  Neodein IT Solutions specializes in providing comprehensive IT services to meet the diverse needs of businesses. From
                    managing IT infrastructure to procuring hardware and software, implementing cloud solutions, and enhancing digital security, we offer a wide array of services tailored to
                    safeguarding your assets both physically and digitally.</p>
                <p data-aos="fade-up" className='aboutus__description'> Our collaborative approach allows us to seamlessly integrate with your existing IT department, leveraging our suite of
                    tools to reinforce protection, enhance accountability, and optimize workflow processes. With Neodein IT Solutions, you can trust that your IT needs are in capable hands, enabling
                    you to focus on driving your business forward</p>
                <h2 data-aos="fade-up" className='aboutus___title2'>Get IT Solutions From Expert Consultants</h2>
                <div data-aos="fade-right" className='aboutus__card'>
                    <Groups2Icon className='aboutus__card__icons' style={{fontSize: '3rem'}}/>
                    <p className='aboutus__card__info'> Faster and Smarter Solutions</p>
                </div>
            </div>
            <div data-aos="fade-left" className='aboutus__img__container'>
            <img className='aboutus__img' src={photo} alt="Company" /> 
            </div>
        </div>
       
    </section>
    )
};

export default Aboutus;