import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HomeHeader from '../../Components/Header/HomeHeader';
import ServiceDetails from '../../Components/Hero/ServiceDetails';
import Navbar from '../../Components/ServicesDetails/NavBar';
import backgroundImage from '../../Assets/wallpaperservicces.jpg';
import './Details.css';
import Footer from '../../Components/Footer/Footer';
import Networkcontent from '../../Components/ServicesDetails/ServicesDisplay/NetworkInfrastructure';
import ScrollToTopButton from '../../Components/ScollButton/ScrollButton';
import logo1 from '../../Assets/Sophos.png';
import logo2 from '../../Assets/cisco.png';
import logo3 from '../../Assets/ubiquiti.png';
import logo4 from '../../Assets/Datto.png';
import logo5 from '../../Assets/Fortinet.png';

const Network = () => {
    const settings = {
        speed: 500,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 1000,
        slidesToShow: 5,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 1920,
                settings: {
                    slidesToShow: 5,
                },
            },
        ],
    };

    return ( 
        <section style={{overflowX: 'hidden'}}>
            <HomeHeader/>
            <ServiceDetails/>
            <div style={{backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)), url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center center', backgroundAttachment:'fixed'}}>
            <div className='details__cont'>
                <div>
                    <Navbar/>
                    </div>
                
                    <div className='details__content' >
                        <Networkcontent/> 
                    </div>
               </div>
               <div className='details__logos' style={{backgroundColor: 'rgb(255,255,255,0.5)', padding: '1rem'}}>
               <Slider {...settings}>
                <div>
                    <img src={logo1} alt="sophos" style={{width: '90%', height: '5rem'}}/>
                </div>
                <div>
                    <img src={logo2}  alt="cisco" style={{width: '90%', height: '5rem'}}/>
                </div>
                <div>
                    <img src={logo3} alt="ubiquiti" style={{width: '90%', height: '5rem'}}/>
                </div>
                <div>
                    <img src={logo4} alt="datto" style={{width: '90%', height: '4rem'}}/>
                </div>
                <div>
                    <img src={logo5} alt="Fortinet" style={{width: '90%', height: '5rem'}}/>
                </div>
                </Slider>
            </div> 
            </div>
            <ScrollToTopButton/>
            <Footer/>

        </section>
     );
}
 
export default Network;