import React, {useEffect} from "react";
import "./DattoRmmDetails.css"; 
import photo1 from "../../Assets/dattoedradvancesecurity.jpg"                                                                                                                                                                                                                                                                                                                  
import photo2 from "../../Assets/dattoedrmagnify.jpg"
import photo3 from "../../Assets/dattoedrshield.jpg"
import photo4 from "../../Assets/dattoedrlightbulb.jpg"
import photo5 from "../../Assets/rmmpatch.jpg"
import Aos from "aos";
import "aos/dist/aos.css";

const DattoEdrDetails = () => {
    useEffect(() => {
        Aos.init({once:true, duration: 2500});
    }, []); 
    return ( 
        <section className="rmm__section">
            <div className="rmm__section__container">
                <div className="rmm__section__card">
                    <div data-aos="fade-right" className="rmm__section__info">
                        <h1 className="rmm__section__title-1">Refined Endpoint Threat Detection and Response</h1>
                        <p  className="rmm__section__description">
                            Datto EDR provides advanced threat detection and response 
                            capabilities that empower you to defend against cyber threats, 
                            mitigate risks, and protect your organization's assets. 
                        </p>
                        <p className="rmm__section__description">
                            With continuous monitoring, automated response actions, 
                            and expert support, we're your trusted partner in endpoint 
                            security excellence.
                        </p>
                    </div>
                    <div data-aos="fade-left" className="rmm__section__image">
                        <img src={photo1} alt="img 1" className="image" style={{height:'100%', width:'100%', objectFit:'cover',borderRadius:"5px", boxShadow: " 10px 10px 10px rgba(128, 128, 128, 0.3)"}}/>
                    </div>
                </div>
                <div className="rmm__section__card">
                    <div data-aos="fade-right" className="rmm__section__image-2">
                            <img src={photo2} alt="img 2" className="image" style={{height:'100%', width:'100%', objectFit:"cover", borderRadius:"5px", boxShadow: "0 4px 8px 0 rgba(255, 255, 255, 0.404), 0 6px 20px 0 rgba(255, 255, 255, 0.411)"}}/>
                        </div>
                    <div data-aos="fade-left" className="rmm__section__info">
                        <h1 className="rmm__section__title-1">Optimized Behavioral Analysis</h1>
                        <p  className="rmm__section__description">
                            The mechanism goes beyond traditional signature-based detection methods,
                            identifying zero-day exploits, polymorphic malware, and other evasive 
                            threats that may evade traditional security measures.
                        </p>
                        <h1 className="rmm__section__title-1">Continuous Monitoring</h1>
                        <p  className="rmm__section__description">
                            Whether your devices are on-premises or remote, Datto EDR provides 
                            round-the-clock protection, ensuring that any threats are detected 
                            and addressed promptly. 
                        <p  className="rmm__section__description">
                            Continuous monitoring enables proactive threat 
                            detection and response, reducing the likelihood of successful cyber attacks 
                            and minimizing the impact on your organization.
                        </p>
                        </p>
                    </div>
                </div>
                <div className="rmm__section__card">
                    <div data-aos="fade-right" className="rmm__section__info">
                        <h1 className="rmm__section__title-1">Threat Hunting</h1>
                        <p  className="rmm__section__description">
                            Threat hunting allows us to identify sophisticated, stealthy 
                            threats that may be lurking in your environment, providing 
                            valuable insights into attacker tactics, techniques, and procedures.
                        </p>
                        <h1 className="rmm__section__title-1">Customizable Policies and Alerts</h1>
                        <p  className="rmm__section__description">
                            Customize policies to enforce security controls such as application 
                            whitelisting, device encryption, and access restrictions based on user
                                roles
                        </p>
                        <p  className="rmm__section__description">
                            Set alert thresholds and define response actions to align with 
                            your organization's risk tolerance and compliance requirements.
                        </p>
                    </div>
                    <div data-aos="fade-left" className="rmm__section__image">
                            <img src={photo3} alt="img 3" className="image" style={{height:'100%', width:'100%', objectFit:"cover", borderRadius:"5px", boxShadow: " 10px 10px 10px rgba(128, 128, 128, 0.4)"}}/>
                        </div>
                </div>
                <div className="rmm__section__card">
                    <div data-aos="fade-right" className="rmm__section__image-2">
                            <img src={photo4} alt="img 4" className="image" style={{height:'100%', width:'100%', objectFit:"cover", borderRadius:"5px", boxShadow: "0 4px 8px 0 rgba(255, 255, 255, 0.404), 0 6px 20px 0 rgba(255, 255, 255, 0.411)"}}/>
                        </div>
                    <div className="rmm__section__info">
                        <h1 data-aos="fade-left" className="rmm__section__title-1">Integrates with Existing Tools</h1>
                        <p data-aos="fade-left" className="rmm__section__description">
                            Integrate with Security Information and Event Management (SIEM) solutions 
                            to correlate endpoint data with network events and security incidents. 
                            Connect with ticketing systems to automate incident response and streamline 
                            the incident management process.
                        </p>
                        <h1 data-aos="fade-left" className="rmm__section__title-1">Automated Response Actions</h1>
                        <p data-aos="fade-left" className="rmm__section__description">
                            Respond swiftly to security incidents with Datto EDR's automated response actions. 
                            Our platform empowers you to automate containment, remediation, and recovery processes,
                            minimizing damage and reducing the burden on your IT team.
                        </p>
                    </div>
                </div>
                <div className="rmm__section__card">
                    <div data-aos="fade-right" className="rmm__section__info">
                        <h1 className="rmm__section__title-1">Expert Support and Guidance</h1>
                        <p className="rmm__section__description">
                            Gain access to our team of cybersecurity experts who are dedicated to helping you succeed.
                            From onboarding and implementation to ongoing support and guidance, we're here to ensure 
                            that you get the most out of Datto EDR.
                        </p>
                    </div>
                    <div data-aos="fade-left" className="rmm__section__image">
                            <img src={photo5} alt="img 5" className="image" style={{height:'100%', width:'100%', objectFit:"cover", borderRadius:"5px", boxShadow: " 10px 10px 10px rgba(128, 128, 128, 0.4)"}}/>
                        </div>
                </div>
            </div>
        </section>
     );
}
 
export default DattoEdrDetails;