import React, {useEffect} from "react";
import './Products.css';
import photo1 from "../../Assets/dattormm.jpg"
import photo2 from "../../Assets/rocketcyber2.jpg"
import photo3 from "../../Assets/dattoedr.jpg"
import photo4 from "../../Assets/autotask2.png"
import photo5 from "../../Assets/itglue.jpg"
import photo6 from "../../Assets/rft1.jpg"
import photo7 from "../../Assets/BCDR.jpg"
import Aos from "aos";
import "aos/dist/aos.css";

const Products = () => {
    useEffect(() => {
        Aos.init({once:true, duration: 2500});
    }, []); 
    return ( 
        <section className="products">
            <h1 data-aos="fade-up" className="products__title"> available products</h1>
            <div className="products__container">
                <div className="products__section">
                    <div className="products__sections">
                        <div data-aos="fade-down-right" className="products__item">
                            <div className="products__img">
                                <img src={photo1} alt="Product 1" style={{height:'100%', width:'100%', borderRadius: '15px 15px 0 0'}} />
                            </div>
                            <a href="/product/datto-rmm" className="products__link">
                                <div className="products__card">
                                    <h2 className="products__title-2">Datto RMM</h2>
                                    <p className='products__description'>
                                    Datto RMM, which stands for Remote Monitoring and Management, is a 
                                    powerful and comprehensive remote IT management, monitoring, and automation platform.
                                    </p>
                                    <div className="products__link__container">
                                    <a href="/product/datto-rmm" className="products__link">Learn More</a>                                
                                </div>
                                </div>                         
                            </a>
                        </div>
                        <div data-aos="fade-down" className="products__item">
                            <div className="products__img">
                                <img src={photo2} alt="Product 2" style={{height:'100%', width:'100%', borderRadius: '15px 15px 0 0'}} />
                            </div>
                            <a href="/product/rocketcyber" className="products__link">
                                <div className="products__card">
                                <h2 className="products__title-2">RocketCyber</h2>
                                <p className='products__description'>
                                    RocketCyber is a leading cybersecurity platform empowering 
                                    organizations with robust threat detection, response 
                                    capabilities, and real-time monitoring methods.
                                </p>
                                <div className="products__link__container">   
                                <a href="/product/rocketcyber" className="products__link">Learn More</a>                          
                                </div>                              
                                </div>
                            </a>       
                        </div>
                        <div data-aos="fade-down-left" className="products__item">
                            <div className="products__img">
                                <img src={photo3} alt="Product 3" style={{height:'100%', width:'100%', borderRadius: '15px 15px 0 0'}}/>
                            </div>
                            <a href="/product/datto-edr" className="products__link">
                                <div className="products__card">
                                <h2 className="products__title-2">Datto EDR</h2>
                                <p className='products__description'>
                                    Datto EDR continuously monitors endpoints for 
                                    suspicious activity, using an advanced behavioral 
                                    assessments and real-time threat detection techniques.
                                    </p>
                                    <div className="products__link__container">
                                    <a href="/product/datto-edr" className="products__link">Learn More</a>                                
                                </div>                              
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className='products__sections'>
                        <div data-aos="fade-up-right" className="products__item">
                            <div className="products__img">
                             <img src={photo4} alt="Product 4" style={{height:'100%', width:'100%', borderRadius: '15px 15px 0 0', objectFit:"cover"}}/>
                            </div>
                            <a href="/product/autotask" className="products__link">
                                <div className="products__card">
                                <h2 className="products__title-2">AutoTask</h2>
                                <p className='products__description'>
                                    Autotask is a versatile business management platform 
                                    designed to streamline operations and maximize efficiency 
                                    for IT service providers and managed service providers (MSPs). 
                                    </p>
                                    <div className="products__link__container">
                                    <a href="/product/autotask" className="products__link">Learn More</a>                                
                                </div>
                                </div>
                            </a>
                                
                            </div>
                        <div data-aos="fade-up" className="products__item">
                            <div className="products__img">
                             <img src={photo5} alt="Product 5" style={{height:'100%', width:'100%', borderRadius: '15px 15px 0 0', objectFit:"cover"}}/>
                            </div>
                            <a href="/product/itglue" className="products__link">
                                <div className="products__card">
                                <h2 className="products__title-2">IT Glue</h2>
                                <p className='products__description'>
                                    IT Glue stands out as a premier documentation platform 
                                    crafted specifically for IT professionals and managed 
                                    service providers (MSPs) through its central repository.
                                </p>
                                <div className="products__link__container">
                                    <a href="/product/itglue" className="products__link">Learn More</a>                                
                                </div>                             
                                </div>
                            </a>
                        </div>
                        <div data-aos="fade-up-left" className="products__item">
                            <div className="products__img">
                             <img src={photo6} alt="Product 6" style={{height:'100%', width:'100%', borderRadius: '15px 15px 0 0', objectFit:"cover"}}/>
                            </div>
                            <a href="/product/rft" className="products__link">
                                <div className="products__card">
                                <h2 className="products__title-2">Rapid Fire Tools</h2>
                                <p className='products__description'>
                                    Rapid Fire Tools stands at the forefront of providing comprehensive IT assessment, 
                                    compliance, and cybersecurity solutions through its suite of innovative tools
                                </p>
                                <div className="products__link__container">
                                    <a href="/product/rft" className="products__link">Learn More</a>                                
                                </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="products__sections">
                        <div data-aos="fade-down-right" className="products__item">
                            <div className="products__img">
                                <img src={photo7} alt="Product 1" style={{height:'100%', width:'100%', borderRadius: '15px 15px 0 0', objectFit:"cover"}} />
                            </div>
                            <a href="/product/datto-rmm" className="products__link">
                                <div className="products__card">
                                    <h2 className="products__title-2">Business Continuity & Disaster Recovery</h2>
                                    <p className='products__description'>
                                        Datto BCDR provides comprehensive data protection and disaster recovery 
                                        solutions that empower you to safeguard your devices.
                                    </p>
                                    <div className="products__link__container">
                                    <a href="/product/datto-rmm" className="products__link">Learn More</a>                                
                                </div>
                                </div>                         
                            </a>
                        </div>
                        <div data-aos="fade-down" className="products__item-3">
                            {/* <div className="products__img">
                                <img src={photo2} alt="Product 2" style={{height:'100%', width:'100%', borderRadius: '15px 15px 0 0'}} />
                            </div> */}
                            <a href="/product/rocketcyber" className="products__link">
                                <div className="products__card-3">
                                <h2 className="products__title-2"></h2>
                                <p className='products__description'>

                                </p>
                                <div className="products__link__container-3">   
                                <a className="products__link"></a>                          
                                </div>                              
                                </div>
                            </a>       
                        </div>
                        <div data-aos="fade-down-left" className="products__item-3">
                            {/* <div className="products__img">
                                <img src={photo3} alt="Product 3" style={{height:'100%', width:'100%', borderRadius: '15px 15px 0 0'}}/>
                            </div> */}
                            <a href="/product/datto-edr" className="products__link">
                                <div className="products__card-3">
                                <h2 className="products__title-2"></h2>
                                <p className='products__description'>
                                    </p>
                                    <div className="products__link__container-3">
                                    <a className="products__link"></a>                                
                                </div>                              
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
     );
}
 
export default Products;