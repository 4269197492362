import React from 'react';
import './NavBar.css';

const Navbar = () => {
    return ( 
        <section className='navbar'>
            <div className='navbar__container'>
                <a href='/service/managed-services-provider' className='nav__link'>
                Managed Services Provider
                </a>
                <a href='/service/server-administration' className='nav__link'>
                Server Administration
                </a>
                <a href='/service/cloud-compute-solutions' className='nav__link'>
                Cloud Compute Solutions
                </a>            
                <a href='/service/network-infrastruture' className='nav__link'>
                Network Infrastructure
                </a>
            </div>            
        </section>
    );
}
 
export default Navbar;
