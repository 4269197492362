import React, { useState } from 'react';
import './HomeHeader.css';
import logo from '../../Assets/logo.png'; 
import MenuIcon from '@mui/icons-material/Menu';

const HomeHeader = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <div>
     
      <header className="home__header">
      <nav className="home__header__nav__main">
      <a href='/' className='home__header__link'>  <img src={logo} alt="Logo" style={{zIndex:'2'}} className="home__header__logo" />  </a>
        <div className='black__bg'style={{zIndex:'-1'}}>
      </div>
        {/* <h2 className="home__header__title"> Neodein</h2> */}
        <div className="home__header__nav">
          <a href="/" className="home__header__nav__link">Home</a>
          <a href="/about" className="home__header__nav__link">About</a>
          <a href="/product" className="home__header__nav__link">Products </a>
          <a href="/service" className="home__header__nav__link">Services</a>
          <a href="/contact" className="home__header__nav__link">Contact</a>
        </div>
        <div className="Header__menuicon" onClick={toggleNav}>
          <MenuIcon  style={{fontSize: '3rem'}} />
        </div>
      </nav>
      {isNavOpen && (
        <section className='homenav__main'>
        <nav className="homenav">
          <a href="/" style={{textDecoration: 'none',}} className="homenav__link">Home</a>
          <a href="/about" style={{textDecoration: 'none',}} className="homenav__link">About</a>
          <a href="/product" style={{textDecoration: 'none',}} className="homenav__link">Products</a>
          <a href="/service" style={{textDecoration: 'none',}} className="homenav__link">Services</a>
          <a href="/contact" style={{textDecoration: 'none',}} className="homenav__link">Contact</a>
        </nav>
        </section>
       
      )}
    </header>
    </div>
  );
};

export default HomeHeader;

