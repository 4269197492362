import React, {useEffect} from "react";
import "./DattoRmmDetails.css"; 
import photo1 from "../../Assets/rocketcyberconsole.png"                                                                                                                                                                                                                                                                                                                  
import photo2 from "../../Assets/rocketcybersoc.png"
import photo3 from "../../Assets/rocketcybersecurity.jpeg"
import photo4 from "../../Assets/rocketcyberscale.jpg"
import photo5 from "../../Assets/rocketcyberlogo.png"
import Aos from "aos";
import "aos/dist/aos.css";

const RocketCyberDetails = () => {
    useEffect(() => {
        Aos.init({once:true, duration: 2500});
    }, []); 
    return ( 
        <section className="rmm__section">
            <div className="rmm__section__container">
                <div className="rmm__section__card">
                    <div data-aos="fade-right" className="rmm__section__info">
                        <h1 className="rmm__section__title-1">RocketCyber: Comprehensive Cybersecurity Suite</h1>
                        <p  className="rmm__section__description">
                            RocketCyber offers a comprehensive suite of cybersecurity services 
                            tailored to protect your business from a wide range of threats. 
                            From endpoint protection to network monitoring, we've got you 
                            covered.
                        </p>
                        <p className="rmm__section__description">
                            RocketCyber empowers businesses of all sizes to defend against 
                            cyber threats, mitigate risks, and achieve peace of mind in an 
                            increasingly complex digital landscape. With our comprehensive 
                            suite of services, expert support, and user-friendly interface,
                            we're your trusted partner in cybersecurity excellence.
                        </p>
                    </div>
                    <div data-aos="fade-left" className="rmm__section__image">
                        <img src={photo1} alt="img 1" className="image" style={{height:'100%', width:'100%', objectFit:'cover',borderRadius:"5px", /*boxShadow: " 10px 10px 10px rgba(128, 128, 128, 0.35)*"*/}}/>
                    </div>
                </div>
                <div className="rmm__section__card">
                    <div data-aos="fade-right" className="rmm__section__image-2">
                            <img src={photo2} alt="img 2" className="image" style={{height:'100%', width:'100%', objectFit:"cover", borderRadius:"5px", boxShadow: "0 4px 8px 0 rgba(255, 255, 255, 0.404), 0 6px 20px 0 rgba(255, 255, 255, 0.411)"}}/>
                        </div>
                    <div data-aos="fade-left" className="rmm__section__info">
                        <h1 className="rmm__section__title-1">Actionable Threat Intelligence</h1>
                        <p  className="rmm__section__description">
                            Gain valuable insights into emerging threats with RocketCyber's 
                            actionable threat intelligence. Stay one step ahead of cybercriminals 
                            and proactively safeguard your digital assets with our advanced analytics.
                        </p>
                        <h1 className="rmm__section__title-1">Customizable Security Policies</h1>
                        <p  className="rmm__section__description">
                            Tailor security policies to meet the unique needs of your business
                             with RocketCyber's customizable security settings. Whether you're 
                             in healthcare, finance, or any other industry, we ensure compliance 
                             and protection at every level.
                        </p>
                    </div>
                </div>
                <div className="rmm__section__card">
                    <div data-aos="fade-right" className="rmm__section__info">
                        <h1 className="rmm__section__title-1">Continuous Monitoring and Alerts</h1>
                        <p  className="rmm__section__description">
                            Stay informed about the health of your devices with RocketCyber's 
                            continuous monitoring and alerts. Receive instant notifications about 
                            suspicious activity, unauthorized access attempts, and other potential 
                            threats, allowing you to take proactive measures to protect your data.
                        </p>
                        <h1 className="rmm__section__title-1">360-Degree Device Protection</h1>
                        <p  className="rmm__section__description">
                            RocketCyber delivers comprehensive protection for all your devices, 
                            from laptops to desktops. With its advanced security solutions, you 
                            can safeguard every endpoint against a myriad of cyber threats.
                        </p>
                    </div>
                    <div data-aos="fade-left" className="rmm__section__image">
                            <img src={photo3} alt="img 3" className="image" style={{height:'100%', width:'100%', objectFit:"cover", borderRadius:"5px", boxShadow: " 10px 10px 10px rgba(128, 128, 128, 0.35)"}}/>
                        </div>
                </div>
                <div className="rmm__section__card">
                    <div data-aos="fade-right" className="rmm__section__image-2">
                            <img src={photo4} alt="img 4" className="image" style={{height:'100%', width:'100%', objectFit:"cover", borderRadius:"5px", boxShadow: "0 4px 8px 0 rgba(255, 255, 255, 0.404), 0 6px 20px 0 rgba(255, 255, 255, 0.411)"}}/>
                        </div>
                    <div className="rmm__section__info">
                        <h1 data-aos="fade-left" className="rmm__section__title-1">Scalable Solution</h1>
                        <p data-aos="fade-left" className="rmm__section__description">
                            Whether you're a small startup or a large enterprise, RocketCyber 
                            scales to fit your needs. Its flexible solutions grow with your business, 
                            ensuring that you always have the right level of protection, no matter
                            how big you become.
                        </p>
                        <h1 data-aos="fade-left" className="rmm__section__title-1">24/7 Threat Detection and Response</h1>
                        <p data-aos="fade-left" className="rmm__section__description">
                            RocketCyber constantly monitors your network for suspicious activity, 
                            providing real-time threat detection and response. With RocketCyber,
                            you can rest assured that your business is always protected, day or 
                            night.
                        </p>
                    </div>
                </div>
                <div className="rmm__section__card">
                    <div data-aos="fade-right" className="rmm__section__info">
                        <h1 className="rmm__section__title-1">Cost-Effective Security</h1>
                        <p className="rmm__section__description">
                            Protecting your business shouldn't break the bank. RocketCyber 
                            offers cost-effective security solutions that deliver maximum 
                            value for your investment. Say goodbye to bloated budgets and 
                            hello to robust protection at an affordable price.
                        </p>
                    </div>
                    <div data-aos="fade-left" className="rmm__section__image">
                            <img src={photo5} alt="img 5" className="image" style={{height:'100%', width:'100%', objectFit:"cover", borderRadius:"5px", boxShadow: " 10px 10px 10px rgba(128, 128, 128, 0.35)"}}/>
                        </div>
                </div>
            </div>
        </section>
     );
}
 
export default RocketCyberDetails;