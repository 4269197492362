import React from 'react';
import Footer from '../Components/Footer/Footer';
import HomeHeader from '../Components/Header/HomeHeader'; 
import Aboutus from '../Components/AboutUs/AboutUs';
import AboutUsHero from '../Components/Hero/AboutUsHero';
import MissionStatement from '../Components/MissionStatement';
import ScrollToTopButton from '../Components/ScollButton/ScrollButton';

const About = () => {
  return (
    <div style={{backgroundColor:'#000', overflowX: 'hidden'}}>
      <HomeHeader/>
      <AboutUsHero/>
      <Aboutus/>
      <MissionStatement/>
      <ScrollToTopButton/>
      <Footer />
    </div>
  );
};

export default About;
